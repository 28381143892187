import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import './gui.js'
import GUI from './gui.js'
import gsap from 'gsap'
import { Color, LoadingManager } from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass'
import Stats from 'stats.js'

let preview = document.querySelector(".preview");
let langSelect = document.querySelector(".lang-select");
let langButtons = document.querySelectorAll(".ger, .eng");

let firstLogo = document.querySelector(".lang-select .logo")
let secondLogo = document.querySelector(".lang-select .second-screen .logo");
let thirdLogo = document.querySelector(".preview .top .logo");
let firstScreen = document.querySelector(".first-screen");
let secondScreen = document.querySelector(".second-screen");
let canvasOverlay = document.querySelector(".canvas-overlay");
langButtons.forEach((btn) => {

    btn.addEventListener("click", () => {
        firstScreen.classList.add("fadeOut")
        firstLogo.classList.add("fadeOut")
        secondLogo.classList.add("d-none")
        preview.classList.remove("d-none")
        thirdLogo.classList.add("d-none")
        setTimeout(() => {
            thirdLogo.classList.remove("d-none")
            langSelect.classList.add("background-animation")
            secondScreen.classList.remove("d-none")
            setTimeout(() => {
                langSelect.remove();
                secondScreen.remove();
            }, 1100);
        }, 1100);
    });
});

let loadingButtons = document.querySelectorAll(".content .btn");
loadingButtons.forEach((btn) => {

    btn.addEventListener("click", () => {
        preview.classList.add("fadeOut");
        canvasOverlay.classList.remove("d-none")
        canvasOverlay.classList.add("fadeIn")
        setTimeout(() => {
            preview.remove();
            load();
        }, 1100);
    });
});


function load() {
    const textContent = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."

    let gui;
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    const canvas = document.querySelector('canvas.webgl')
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100);
    const orbitControls = new OrbitControls(camera, canvas);

    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
    });

    const mouse = new THREE.Vector2()
    const raycaster = new THREE.Raycaster()
    const clock = new THREE.Clock()

    const loadingScreen = document.querySelector(".loading-screen");
    const loadingBar = document.querySelector(".loading-bar");
    const loadingManager = new LoadingManager(
        () => {
            loadingBar.style.opacity = "0";
            loadingScreen.style.opacity = "0";
            setTimeout(() => {
                loadingScreen.remove();
                startRender();
            }, 200);

        },
        (itemUrl, itemAmount, itemsTotal) => {
            const ratio = itemAmount / itemsTotal;
            loadingBar.style.transform = `scaleX(${ratio})`;
        }
    );
    const gltfLoader = new GLTFLoader(loadingManager);
    const fontLoader = new FontLoader(loadingManager);

    const centerPoint = new THREE.Object3D();
    const axesHelper = new THREE.AxesHelper();
    const gridHelper = new THREE.GridHelper(10, 10);

    const hemisphereLight = new THREE.HemisphereLight("#0061a0", "#333", 1);
    const ambientLight = new THREE.AmbientLight("#313a7c");
    const directionalLight = new THREE.DirectionalLight("#fff", 1.5);
    const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight);

    const objectsToRaycast = [];

    const outlineColorDark = new THREE.Color("#333");
    const outlineColorHighlight = new THREE.Color("#fff");
    const insideColor = new THREE.Color("#666");

    const effectComposer = new EffectComposer(renderer)
    const pointsOfInterest = [];

    let font = null;
    let textGeometry = null;
    const textGeometries = [];

    const stats = new Stats();

    (function debugSetup() {
        scene.add(centerPoint);
        return;
        scene.add(gridHelper);
        scene.add(axesHelper);
        scene.add(directionalLightHelper);


        stats.showPanel(0);
        document.body.append(stats.dom);
    })();

    (function lightSetup() {
        directionalLight.position.set(0, 1, 3);
        directionalLight.target.position.set(0, 0, 0);

        scene.add(directionalLight);
        scene.add(directionalLight.target);

        scene.add(ambientLight);
        scene.add(hemisphereLight);

        scene.background = new THREE.Color("#fff");
    })();

    (function cameraSetup() {
        camera.position.set(5.431, 2.72, 5.81)
        camera.rotation.set(-0.54, 0.805, 0.4);
        scene.add(camera);

        orbitControls.enableDamping = true;
        orbitControls.enablePan = true;
    })();

    (function rendererSetup() {
        const renderPass = new RenderPass(scene, camera);

        const ssaoPass = new SSAOPass(scene, camera);
        const dotScreenPass = new DotScreenPass();
        const unrealBloomPass = new UnrealBloomPass();
        const saoPass = new SAOPass(scene, camera);

        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.physicallyCorrectLights = false;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;

        effectComposer.setSize(sizes.width, sizes.height)
        effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        effectComposer.addPass(renderPass);
        //effectComposer.addPass(dotScreenPass);
        //effectComposer.addPass(unrealBloomPass);
        //unrealBloomPass.strength = 0.3

        // effectComposer.addPass(dotScreenPass);

        //ssaoPass.kernelRadius = 10;
        //ssaoPass.minDistance = 0.001;
        //ssaoPass.maxDistance = 0.02;
        //ssaoPass.renderToScreen = true;
        //effectComposer.addPass(ssaoPass);
    })();

    (function guiSetup() {
        return
        gui = new GUI();
        gui.backgroundColorChangeCallback = (data) => scene.background = new Color(data);
        gui.ambientLightColorChangeCallback = (data) => ambientLight.color = new Color(data);
        gui.hemisSphereTopColorChangeCallback = (data) => hemisphereLight.color = new Color(data);
        gui.hemisSphereGroundColorChangeCallback = (data) => hemisphereLight.groundColor = new Color(data);
        gui.directionLightColorChangeCallback = (data) => directionalLight.color = new Color(data);
        gui.toneMappingChangeCallback = (data) => renderer.toneMapping = data;
        gui.encodingChangeCallback = (data) => renderer.outputEncoding = data;

        gui.wireFrameChangeCallback = (data) => updateAllMaterials(data);

        gui.debugChangeCallback = (data) => {
            gridHelper.visible = data;
            axesHelper.visible = data;
            directionalLightHelper.visible = data;
        }
    })();

    (function load3DModel() {
        gltfLoader.load("/3D Models/kuhn_d.gltf", (gltf) => {

            gltf.scene.position.y += 1
            scene.add(gltf.scene)
            pointsOfInterestSetup();
            updateAllMaterials();
        });
    })();

    (function fontSetup() {
        fontLoader.load(
            '/fonts/helvetiker_regular.typeface.json',
            (loadedFont) => {
                font = loadedFont
            }
        )
    })();

    function startRender() {
        let time = Date.now();
        const update = () => {
            stats.begin();
            const elapsedTime = clock.getElapsedTime()

            const currentTime = Date.now();
            const deltaTime = currentTime - time;
            time = currentTime;

            textGeometries.forEach((geo) => geo.lookAt(camera.position));
            hoverRayCast();

            //effectComposer.render();
            orbitControls.update();

            renderer.render(scene, camera)
            window.requestAnimationFrame(update)
            stats.end();
        }
        update();
    }

    function updateAllMaterials(wireframe = false) {

        /*  const textureLoader = new THREE.TextureLoader()
          const gradientTexture = textureLoader.load('textures/gradients/3.jpg')
          gradientTexture.magFilter = THREE.NearestFilter
          const m = new THREE.MeshStandardMaterial({ color: 'white' });*/
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                //child.material = m;

                //child.material.envMap = environmentMap
                child.material.wireframe = wireframe;
                child.material.envMapIntensity = 2.5
                child.castShadow = false
                child.receiveShadow = false
            }
        })
    }

    function pointsOfInterestSetup() {

        let i = 0;
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh && child.name.includes("Poi")) {
                i++;
                const poiMaterial = new THREE.MeshBasicMaterial({ color: '#555', opacity: 0.75, side: THREE.DoubleSide });
                poiMaterial.depthTest = false;
                poiMaterial.depthWrite = false;
                poiMaterial.transparent = true;
                child.material = poiMaterial;

                const poiOutlineMaterial = new THREE.MeshBasicMaterial({ color: insideColor, opacity: 1, side: THREE.BackSide });
                poiOutlineMaterial.depthTest = false;
                poiOutlineMaterial.depthWrite = false;
                poiOutlineMaterial.transparent = true;

                const outlineSphere = new THREE.Mesh(new THREE.SphereGeometry(.13), poiOutlineMaterial);
                outlineSphere.position.set(child.position.x, child.position.y + 1, child.position.z);
                scene.add(outlineSphere)


                textGeometry = new TextGeometry(child.name, {
                    font: font,
                    size: 0.1,
                    height: 0,
                    curveSegments: 3,
                    bevelEnabled: true,
                    bevelThickness: 0,
                    bevelSize: 0,
                    bevelOffset: 0,
                    bevelSegments: 0
                });
                textGeometry.center();
                const text = new THREE.Mesh(textGeometry, new THREE.MeshBasicMaterial({ color: 'red' }))
                text.material.depthTest = false;
                text.material.depthWrite = false;
                text.renderOrder = 999;

                textGeometries.push(text);
                text.visible = false;
                scene.add(text)

                text.position.set(child.position.x, child.position.y + 1.25, child.position.z + .1)


                const htmlText = document.createElement("div")
                htmlText.setAttribute("class", "text2d");
                htmlText.innerHTML = `
                <div class="d-flex">
                    
                    <h3 class="d-block">${child.name}</h3>
                    <div class="close" id="close-${child.name}">x</div>
                </div>
                
                ` + textContent;
                document.body.appendChild(htmlText)

                let btn = document.getElementById(`close-${child.name}`);
                btn.addEventListener("click", () => {
                    htmlText.classList.remove("visible");
                    htmlText.style.opacity = 0;
                });

                pointsOfInterest.push({
                    position: child.position,
                    rotation: child.rotation,
                    object: child,
                    text: text,
                    htmlText: htmlText,
                    outlineMaterial: poiOutlineMaterial
                });

                objectsToRaycast.push(child);
            }
        })
    }

    function getMousePosition(event) {
        mouse.x = event.clientX / sizes.width * 2 - 1
        mouse.y = -(event.clientY / sizes.height) * 2 + 1
    }

    function getMouseTouchPosition(event) {
        mouse.x = (event.targetTouches[0].pageX / window.innerWidth) * 2 + -1;
        mouse.y = -(event.targetTouches[0].pageY / window.innerHeight) * 2 + 1;
    }

    function hoverRayCast() {
        if (!font) return;

        let currentIntersect = null;
        raycaster.setFromCamera(mouse, camera)

        const intersects = raycaster.intersectObjects(objectsToRaycast)
        currentIntersect = intersects[0]

        if (currentIntersect) {
            const index = pointsOfInterest.findIndex((other) => other.object.name === currentIntersect.object.name);
            const poi = pointsOfInterest[index];

            poi.text.visible = true;
            poi.object.material.color = outlineColorHighlight;

            for (let i = 0; i < pointsOfInterest.length; i++) {
                if (i === index) continue;
                pointsOfInterest[i].visible = false;
            }
            document.body.style.cursor = "pointer";
            return;
        }

        document.body.style.cursor = "auto";
        for (let i = 0; i < pointsOfInterest.length; i++) {
            pointsOfInterest[i].text.visible = false;
            pointsOfInterest[i].object.material.color = outlineColorDark;
        }
    }

    function shootRayCast() {
        let currentIntersect = null
        raycaster.setFromCamera(mouse, camera)

        const intersects = raycaster.intersectObjects(objectsToRaycast)
        currentIntersect = intersects[0]

        if (currentIntersect) {
            const index = pointsOfInterest.findIndex((other) => other.object.name === currentIntersect.object.name);
            const poi = pointsOfInterest[index];

            poi.htmlText.style.opacity = 1;
            poi.htmlText.classList.add("visible");

            gsap.to(poi.object.material, {
                duration: 1,
                opacity: 0
            })

            gsap.to(poi.outlineMaterial, {
                duration: 1,
                opacity: 0
            })

            gsap.to(orbitControls.target, {
                duration: 1,
                delay: 0.2,
                x: poi.position.x,
                y: poi.position.y + .65,
                z: poi.position.z,
            });

            gsap.to(camera.position, {
                duration: 1,
                delay: 0.2,
                x: poi.position.x,
                y: poi.position.y + .65,
                z: poi.position.z + 2
            });
            return;
        }

        for (let i = 0; i < pointsOfInterest.length; i++) {
            let distance = camera.position.distanceTo(pointsOfInterest[i].position);
            if (distance < 3.5) continue;
            //  if (lastPoi != null && lastPoi.object.name === pointsOfInterest[i].object.name) return;
            pointsOfInterest[i].htmlText.style.opacity = 0;
            pointsOfInterest[i].htmlText.classList.remove("visible");

            gsap.to(pointsOfInterest[i].object.material, {
                duration: 1,
                opacity: 1
            })

            gsap.to(pointsOfInterest[i].outlineMaterial, {
                duration: 1,
                opacity: 1
            })

        }
    }

    function handleResize() {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    function handleFullscreen() {
        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

        if (!fullscreenElement) {
            if (canvas.requestFullscreen) {
                canvas.requestFullscreen()
            } else if (canvas.webkitRequestFullscreen) {
                canvas.webkitRequestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        }
    }

    window.addEventListener('mousemove', (event) => getMousePosition(event));
    window.addEventListener('touchmove', (event) => getMouseTouchPosition(event));
    window.addEventListener('touchstart', (event) => getMouseTouchPosition(event));
    window.addEventListener('click', () => shootRayCast());
    window.addEventListener('touchend', () => shootRayCast());
    window.addEventListener('resize', () => handleResize());
    //window.addEventListener('dblclick', () => handleFullscreen());


    const resetBtn = document.querySelector(".reset")
    resetBtn.classList.remove("d-none");
    resetBtn.addEventListener("click", () => {
        resetCameraView();
    })

    function resetCameraView() {
        gsap.to(camera.position, {
            duration: 1,
            x: 5.431,
            y: 2.72,
            z: 5.81
        });

        gsap.to(orbitControls.target, {
            duration: 1,
            delay: 0.2,
            x: 0,
            y: 0,
            z: 0,
        });
    }
};